<template>
  <div class="container">
    <iframe
      class="ifclass"
      :src="privacyUrl"
    ></iframe>
    <!-- <div class="bottom"> 
      <p>
        <router-link :to="{ path: '/regist' }">{{ this.$t("backHome") }}</router-link>
      </p>
    </div> -->
    <!-- <div class="cookie-box">
      <h1 class="classh1">{{ this.$t("privacyPolicyShow") }}</h1>
      <div>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;{{ this.$t("privacyTitle1") }}</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;{{ this.$t("privacyTitle2") }}</p>
      </div>
      <br>
      <h2 class="classh2">{{ this.$t("onlineInformation") }}</h2>
      <br>
      <div>
        <p>{{ this.$t("personInformation") }}</p>
        <br>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;{{ this.$t("personInformationTitle") }}</p>
      </div>
      <div>
        <br>
        <p>{{ this.$t("equipmentInformation") }}</p>
        <br>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;{{ this.$t("equipmentInformationTitle") }}</p>
      </div>
      <br>
      <h2 class="classh2">{{ this.$t("useyourInformation") }}</h2>
      <br>
      <div>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;{{ this.$t("useyourInformationTitle1") }}</p>
        <ul class="ulclass">
            <li>{{ this.$t("useyourInformationTitle2") }}</li>
            <li>{{ this.$t("useyourInformationTitle3") }}</li>
            <li>{{ this.$t("useyourInformationTitle4") }}</li>
            <li>{{ this.$t("useyourInformationTitle5") }}</li>
            <li>{{ this.$t("useyourInformationTitle6") }}</li>
            <li>{{ this.$t("useyourInformationTitle7") }}</li>
            <li>{{ this.$t("useyourInformationTitle8") }}</li>
        </ul>
      </div>
      <br>
      <h2 class="classh2">{{ this.$t("information") }}</h2>
      <br>
      <div>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;{{ this.$t("informationTitle") }}</p>
      </div>
      <br>
      <h2 class="classh2">{{ this.$t("minorsPrivacy") }}</h2>
      <br>
      <div>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;{{ this.$t("minorsPrivacyTitle") }}</p>
      </div>
      <br>
      <h2 class="classh2">{{ this.$t("informationSafety") }}</h2>
      <br>
      <div>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;{{ this.$t("informationSafetyTitle") }}</p>
      </div>
      <br>
      <h2 class="classh2">{{ this.$t("policyModification") }}</h2>
      <br>
      <div>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;{{ this.$t("policyModificationTitle") }}</p>
      </div>
    </div> -->
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      privacyUrl:
        "https://privacy.xmeye.net/xmeye/privacy_" +sessionStorage.getItem("languagevalue") +".html",
    };
  },
  mounted() {
  },
  computed: {
    changeLan: function () {
      return this.$store.state.changeLan;
    },
  },
  watch: {
    changeLan() {
      this.privacyUrl = "https://privacy.xmeye.net/xmeye/privacy_"+sessionStorage.getItem("languagevalue")+".html"
    },
  },
};
</script>
  
  <style scoped>
* {
  margin: 0;
  padding: 0;
}
.ifclass {
  width: 100%;
  height: 100vh;
  overflow: auto;
}
.container {
  width: 100%;
  position: relative;
  /* height: 1000px;
    overflow: auto; */
}
.cookie-box {
  width: 530px;
  margin: 0 auto;
  font-weight: bold;
  /* height: 1800px; */
}
.classh1 {
  text-align: center;
  font-weight: bold;
}
.classh2 {
  font-weight: 600;
}
.ulclass {
  margin-left: 30px;
}
.bottom{
  position: fixed;
  bottom: 0;
  left: 0;
}
</style>
  